<template>
  <div class="hello_js">
    <div v-show="isShow" class="monitor_box" id="video_realtime_box">
      <div id="video-realtime" style="width:100%;height:100%"></div>
    </div>
    <div v-show="!isShow" class="empty_box">
      <span>{{ emptyText }}</span>
    </div>
  </div>

</template>

<script>
import {monitor} from "../../api/monitoring";
import EZUIKit from "ezuikit-js";
import { Message,} from "element-ui";

export default {
  props: {
    // id: {
    //   type: Number,
    //   default: function () {
    //     return {};
    //   }
    // },
  },
  data: () => ({
    elevatorId: null,
    Authorization: null,
    isShow: true,
    dataInfo:null,
    emptyText: '',//空数据文案
    webVersion:null,//浏览器版本
  }),

  //监听小区筛选
  watch: {
  },
  mounted() {
    this.getRequest()
  },
  methods: {
    //获取地址栏参数
    getRequest() {
      var url = location.href.split('?')[1]; //获取url中"?"符后的字串
      var params = url.split('&');
      console.log(url)
      console.log(params)
      var theRequest = new Object();
      for(var i=0;i<params.length;i++){//遍历数组，拿到json对象
        theRequest[params[i].split('=')[0]] = params[i].split('=')[1]
      }
      if(!theRequest.Authorization){
        console.log('缺少Authorization参数')
      }else if(!theRequest.elevatorId){
        console.log('缺少elevatorId参数')
      }else if(!theRequest.webVersion){
        console.log('缺少webVersion参数')
      }else{
        this.Authorization = theRequest.Authorization
        this.elevatorId = theRequest.elevatorId;
        this.webVersion = theRequest.webVersion;
        this.handleView()
      }
    },
    getInfo(data) {
      console.group("mounted 组件挂载完毕状态===============》");
      var div = document.getElementById("video_realtime_box");
      var w = div.offsetWidth; // 返回元素的总宽度
      var h = div.offsetHeight; // 返回元素的总宽度
      console.log('=================');
      console.log(w);
      console.log(h);
      console.log(h-40);
      if (this.player) {
        //销毁EZUIKit
        var id = "video-realtime";
        var element = document.getElementById(id);
        console.log(element);
        element.innerHTML = "";
        this.player = null;
      }
      this.$nextTick(function () {
        this.player = new EZUIKit.EZUIKitPlayer({
          // autoplay: true,
          id: "video-realtime",
          accessToken: data.accessToken,
          // accessToken: 'at.0n0f5ljgabqxrnxuatqb8u4z4mqg57gy-7hz8hluudw-18vuvxh-drcdg8iji',
          // url: 'ezopen://open.ys7.com/D03674688/1.live',
          url: data.url,
          autoplay: true,
          template: "voice", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          // 视频上方头部控件
          header: ["capturePicture", "", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
          //plugin: ['talk'],                       // 加载插件，talk-对讲
          // 视频下方底部控件
          footer: ["talk", "", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
          audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
          // openSoundCallBack: data => console.log("开启声音回调", data),
          // closeSoundCallBack: data => console.log("关闭声音回调", data),
          // startSaveCallBack: data => console.log("开始录像回调", data),
          // stopSaveCallBack: data => console.log("录像回调", data),
          // capturePictureCallBack: data => console.log("截图成功回调", data),
          // fullScreenCallBack: data => console.log("全屏回调", data),
          // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
          width: w,
          height: h-100
          // setTimeout(()=>{
          //   player.stop(); // 方法调用示例，10秒后关闭视频
          // },10000)
        });
      });
    },
    //查看详情
    handleView() {
      var params = {
        eleId: this.elevatorId,
        webVersion: this.webVersion,
      };
      var headers = {
        Authorization:this.Authorization,
        token:this.Authorization,
      };
      monitor(params,headers).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.$set(this, "dataInfo", res.data);
          this.isShow = true
          this.getInfo(res.data);
        } else {
          this.isShow = false
          this.$set(this, "dataInfo", null);
          this.emptyText = res.msg
          Message.warning(res.msg);
        }
      });
      // this.processingTag = true;
    }
  }
};
</script>
<style scoped lang="stylus">
.hello_js {
  width: 100%;
  height: 100%;
  padding: 16px 18px;
  box-sizing: border-box;
  background: #3D3D3D;
}

.monitor_box {
  width: 100%;
  height: 100%;
}

.empty_box {
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/img/empty_bg.png") no-repeat;
  background-size: 100% 100%;
}
</style>
